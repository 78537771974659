<template>
  <div class="page">
    <div class="bannerBox">
      <img src="./img/banner.png" alt="" />
    </div>
    <div class="navBox">
      <div class="nav">
        <div class="leftBox" @click="showSelectType(0)">
          <div class="inbox">
            <div class="text">{{ businessTypeStr }}</div>
            <img src="./img/arrow.png" alt="" />
          </div>
        </div>
        <div class="rightBox" @click="showSelectType(1)">
          <div class="inbox">
            <div class="text">{{ manageTypeStr }}</div>
            <img src="./img/arrow.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="list">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        ref="list"
      >
        <div
          class="item"
          @click="toDetail(item.id)"
          v-for="(item, index) in listData"
          :key="index"
        >
          <div class="item-left"><img :src="item.imageUrl" alt="" /></div>
          <div class="item-right">
            <div class="top">
              <img src="./img/buildIcon.png" alt="" />
              <div class="text">{{ item.title }}</div>
            </div>
            <div class="center">
              商家商品
              <span>{{ item.putawaying }}</span>
              <div class="line"></div>
              累计核销甜蜜值
              <span>{{ item.consumedPonints }}</span>
            </div>
            <div class="bottom">
              <img src="./img/placeIcon.png" alt="" />
              <div class="text">{{ item.detailedAddress }}</div>
            </div>
            <div class="activity" v-if="item.discountInfo.length > 0">
              店铺活动：{{ item.discountInfo }}
            </div>
          </div>
        </div>
      </v-list>
    </div>
    <div class="btn1" v-if="myStatus == 5" @click="toBusinessCircle()">
      申请入驻
    </div>
    <div class="btn1" v-else-if="myStatus == 1" @click="tobusinessCircle()">
      我的商铺
    </div>
    <div class="btn2" v-else-if="myStatus == 0">商铺审核中...</div>
    <div
      class="btn3"
      v-else-if="myStatus == 2"
      @click="toBusinessCircle('isEdit')"
    >
      商铺审核未通过，重新申请
    </div>
    <div class="btn3" v-else-if="myStatus == 3">已冻结</div>
    <div class="btn2" v-else-if="myStatus == 4">申诉中</div>
    <v-picker
      :columns="businessTypeops"
      :valueShow="showPicker"
      :valueKey="'text'"
      @clickOverlay="clickPickOverlay"
      @cancel="cancelPick"
      @confirmPicker="confirmPick"
    ></v-picker>
    <v-picker
      :columns="manageTypeops"
      :valueShow="showPicker1"
      :valueKey="'text'"
      @clickOverlay="clickPickOverlay1"
      @cancel="cancelPick1"
      @confirmPicker="confirmPick1"
    ></v-picker>
  </div>
</template>

<script>
import { getBusinessCircleStatusUrl, getShopInfoListUrl } from "./api.js";
import {
  businessTypeopsMap,
  businessTypeops,
  manageTypeopsMap,
  manageTypeops,
} from "./map.js";
import { gloabalCount, toRegister } from "@/utils/common";
export default {
  name: "businessDistrictList",
  data() {
    return {
      showPicker: false,
      businessTypeStr: "商圈类型",
      businessTypeCode: null,

      showPicker1: false,
      manageTypeStr: "经营类型",
      manageTypeCode: null,

      businessTypeops,
      manageTypeops,
      businessTypeopsMap,
      manageTypeopsMap,

      finishedText: "没有更多了",
      finished: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 10,
      },

      myStatus: null,
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    communityId() {
      return this.$store.state.communityId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    openId() {
      return this.$store.state.openId;
    },
  },
  created() {
    if (this.$route.query.latitude) {
      localStorage.setItem("latitude", this.$route.query.latitude);
    }
    if (this.$route.query.longitude) {
      localStorage.setItem("longitude", this.$route.query.longitude);
    }
  },
  mounted() {
    gloabalCount("", 74, 1);
    this.getMyStatus();
  },
  methods: {
    // 获取所有商户列表
    onLoad() {
      this.getList();
    },
    getList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
        status: "1",
        businessType: this.businessTypeCode,
        manageType: this.manageTypeCode,
        tenantId: this.tenantId,
        houseId: this.roomId || this.communityId || undefined,
      };
      this.$axios
        .get(`${getShopInfoListUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              this.listData.push(item);
            });
          } else {
          }
        });
    },
    // 获取我的商户申请状态
    async getMyStatus() {
      let params = {
        userId: this.userId,
        tenantId: this.tenantId,
        houseId: this.roomId || this.communityId || undefined,
      };
      let res = await this.$axios.get(`${getBusinessCircleStatusUrl}`, {
        params: params,
      });
      if (res.code == 200) {
        this.myStatus = res.data;
      }
    },
    // 展示类型选择弹框
    showSelectType(num) {
      if (num == 1) {
        this.showPicker1 = true;
      } else {
        this.showPicker = true;
      }
    },
    // 商圈类型
    confirmPick(val) {
      this.businessTypeStr = val.text;
      this.businessTypeCode = val.value;
      this.showPicker = false;
      this.listData = [];
      this.requestData.curPage = 1;
      this.finished = false;
      this.onLoad();
    },
    cancelPick() {
      this.showPicker = false;
    },
    clickPickOverlay() {
      this.showPicker = false;
    },
    // 经营类型
    confirmPick1(val) {
      this.manageTypeStr = val.text;
      this.manageTypeCode = val.value;
      this.showPicker1 = false;
      this.listData = [];
      this.requestData.curPage = 1;
      this.finished = false;
      this.onLoad();
    },
    cancelPick1() {
      this.showPicker1 = false;
    },
    clickPickOverlay1() {
      this.showPicker1 = false;
    },
    // ----------
    toDetail(id) {
      this.$router.push({
        name: "businessDistrictDetail",
        query: {
          id: id,
        },
      });
    },
    tobusinessCircle() {
      this.$router.push({
        name: "businessCircle",
      });
    },
    toBusinessCircle(data) {
      if (data == "isEdit") {
        this.$router.push({
          name: "businessCircleApply",
          query: {
            isEdit: 1,
          },
        });
      } else {
        this.$router.push({
          name: "businessCircleApply",
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background: #f9f9f9;
  padding-bottom: 172px;
  .btn1 {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ffc055 0%, #fe9514 100%);
    border-radius: 10px;
    line-height: 66px;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    color: #ffffff;
    position: fixed;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
  }
  .btn2 {
    width: 550px;
    height: 66px;
    border-radius: 10px;
    line-height: 66px;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    color: #fe9717;
    position: fixed;
    border: 2px solid;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
  }
  .btn3 {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
    border-radius: 10px;
    line-height: 66px;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.25);
    position: fixed;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
  }
  .list {
    width: 100%;
    padding: 0 28px;
    box-sizing: border-box;
    .item {
      width: 100%;
      height: 280px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
      border-radius: 16px;
      margin-bottom: 20px;
      padding: 36px 20px;
      box-sizing: border-box;
      display: flex;
      overflow: hidden;
      .item-right {
        width: calc(100% - 208px);
        height: 100%;
        padding: 0px 0px 0 20px;
        box-sizing: border-box;
        .activity {
          font-size: 24px;
          color: rgba(0, 0, 0, 0.5);
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .bottom {
          display: flex;
          align-items: center;
          margin-bottom: 14px;
          img {
            width: 21px;
            height: 28px;
            margin-right: 28px;
          }
          .text {
            font-size: 24px;
            color: rgba(0, 0, 0, 0.5);
          }
        }
        .center {
          margin-top: 20px;
          margin-bottom: 16px;
          font-size: 24px;
          color: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          height: 32px;
          line-height: 32px;
          span {
            color: #fe9615;
          }
          .line {
            width: 3px;
            height: 32px;
            background-color: rgba(0, 0, 0, 0.1);
            margin: 0 44px;
          }
        }
        .top {
          width: 100%;
          display: flex;
          img {
            width: 36px;
            height: 36px;
            margin-right: 14px;
          }
          .text {
            width: calc(100% - 50px);
            font-size: 28px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            // -webkit-line-clamp: 2;
            // -webkit-box-orient: vertical;
          }
        }
      }
      .item-left {
        width: 208px;
        height: 208px;
        img {
          display: block;
          border-radius: 12px;
          width: 208px;
          height: 208px;
        }
      }
    }
  }
  .navBox {
    width: 100%;
    height: 138px;
    padding: 26px 28px;
    box-sizing: border-box;
    .nav {
      width: 100%;
      height: 100%;
      display: flex;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.49);
      border-radius: 16px;
      .leftBox,
      .rightBox {
        flex: 1;
        display: flex;
        position: relative;
        .inbox {
          display: flex;
          align-items: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          .text {
            font-size: 30px;
            font-weight: 600;
            color: #fe9515;
            margin-right: 28px;
            white-space: nowrap;
          }
          img {
            width: 28px;
            height: 12px;
          }
        }
      }
    }
  }
  .bannerBox {
    width: 100%;
    height: 336px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
